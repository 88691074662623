.emergency-links {
  display: flex;
  max-width: 33rem;
  flex-direction: column;
  .emergency-link {
    width: 100%;
    padding: rem-calc(15) 0;
    margin: rem-calc(15) 0;
  }
}

.emergency-link {
  @extend button;

  background: $sun-yellow;
  color: $black;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3rem;

  &:hover,
  &:focus {
    background: darken($sun-yellow, 10%);
  }
}

#step-1 {
  & > *:last-child:not(.hide) {
    margin-bottom: $block-margin; // reset for emergency button
  }
}
