@font-face {
  font-family: "HelveticaNeueLTPro";
  font-weight: 400;
  src: url("static/assets/fonts/HelveticaNeueLTProRoman/font.woff2")
      format("woff2"),
    url("static/assets/fonts/HelveticaNeueLTProRoman/font.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  font-weight: 700;
  src: url("static/assets/fonts/HelveticaNeueLTProHv/font.woff2")
      format("woff2"),
    url("static/assets/fonts/HelveticaNeueLTProHv/font.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  font-weight: 600;
  src: url("static/assets/fonts/HelveticaNeueLTProMd/font.woff2")
      format("woff2"),
    url("static/assets/fonts/HelveticaNeueLTProMd/font.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  font-weight: 400;
  font-style: italic;
  src: url("static/assets/fonts/HelveticaNeueLTProIt/font.woff2")
      format("woff2"),
    url("static/assets/fonts/HelveticaNeueLTProIt/font.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  font-weight: 700;
  font-style: italic;
  src: url("static/assets/fonts/HelveticaNeueLTProHvIt/font.woff2")
      format("woff2"),
    url("static/assets/fonts/HelveticaNeueLTProHvIt/font.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  font-weight: 600;
  font-style: italic;
  src: url("static/assets/fonts/HelveticaNeueLTProMdIt/font.woff2")
      format("woff2"),
    url("static/assets/fonts/HelveticaNeueLTProMdIt/font.woff") format("woff");
}
