$icons: "single", "group", "physical_attack", "sounds", "exhibitionism",
  "gazing", "gestures", "unwanted_contact", "verbal_harassment", "stalking";
$small-icons: "male", "female", "other";

$icon-size: rem-calc(100);
$small-icon-size: rem-calc(65);

@mixin icons {
  @each $icon in $icons {
    &.#{$icon}::after {
      background-image: url("static/assets/icons/#{$icon}.svg");
      height: $icon-size;
      width: $icon-size;
      max-width: 100%;
    }
  }
  @each $icon in $small-icons {
    &.#{$icon}::after {
      background-image: url("static/assets/icons/#{$icon}.svg");
      height: $small-icon-size;
      width: $small-icon-size;
      max-width: 100%;
    }
  }
}

label.icon:not(.other_incident, .unknown, .mixed),
button.icon:not(.other_incident, .unknown, .mixed) {
  @include icons();
  flex-direction: column;
  &::after {
    content: " ";
    display: block;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.summary-buttons button.icon {
  position: relative;
}

.summary-buttons button.icon::before {
  content: " ";
  position: absolute;
  display: block;
  right: 0.5rem;
  top: 0.5rem;
  width: 1.3rem;
  height: 1.3rem;
  background-image: url("static/assets/icons/pencil.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
