fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.checkboxes {
  > label {
    display: none;
  }
  @extend .grid;
  // margin-top: 0.5rem;
  margin-bottom: $block-margin;

  &.fr-2 {
    checkbox:last-of-type:not(:nth-of-type(2n)) {
      grid-column-end: span 2;
    }
  }
}

checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;

  > input {
    @extend .show-for-sr;
  }
  > label {
    min-width: 0;
    width: 100%;
  }

  > input:checked + label {
    box-shadow: inset 1px 1px 7px rgba(0, 0, 0, 0.4);
    background: $cobalt-blue--dark;
  }
  > input:focus + label,
  > input:hover + label {
    box-shadow: 0 0 0 1px $dark-gray;
  }
}

form[data-help] checkbox > input + label.required {
  animation: help-shadow 3s linear forwards;
}

.summary-buttons {
  @extend .checkboxes;
}

.summary-buttons button,
checkbox label {
  display: flex;
  align-items: center;
  justify-content: center;
  @extend button;
  padding: 1.66rem 1rem;
  margin: 0;
  font-weight: 600;
}

@keyframes help-shadow {
  0% {
    box-shadow: inset 0 0 0 3px $coral-red;
  }
  80% {
    box-shadow: inset 0 0 0 3px $coral-red;
  }
  100% {
    box-shadow: inherit;
  }
}
