summary {
  position: relative;
  display: block;
  cursor: pointer;
  color: $zh-blue;
  font-size: rem-calc(28);
  font-weight: bold;
  margin: $block-margin 2.5rem $block-margin 0;

  &::after {
    width: 2.25rem;
    height: 2.25rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: " ";
    background-image: url("static/assets/plus.svg");
    position: absolute;
    right: -2.25rem;
    top: calc(50% - 1.125rem);
  }
}

details[open] summary::after {
  background-image: url("static/assets/minus.svg");
}

// fix safari, chrome
summary::marker,
summary::-webkit-details-marker {
  display: none;
}

details {
  .warning,
  .contactform {
    width: calc(100%);
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1rem;

    @include breakpoint(md) {
      padding: 1rem 2rem;
      width: calc(100% - 4rem);
      margin: 0;
    }

    color: $white;
  }

  .warning {
    background: $coral-red;
  }

  .contactform {
    background: $cobalt-blue;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .text-content p {
      white-space: pre-wrap;
    }

    button {
      background: $white;
      border: 1px solid $zh-blue;
      color: $coral-red;
      font-weight: normal;
      padding: 0.5rem;
    }

    .field-wrap {
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;
    }
  }
}
