button {
  position: relative;

  padding: 1.125rem;
  margin: 0.75rem;

  border: none;
  border-radius: rem-calc(5);

  display: block;
  text-align: center;

  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;

  background: $cobalt-blue;
  color: $white;

  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus {
    box-shadow: 0 0 0 1px $dark-gray;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: initial;
  }

  &.red {
    color: $white;
    background: $coral-red;
  }
}

buttons {
  display: flex;
  justify-content: center;
}

buttons.links {
  @extend .grid;
  grid-template-columns: minmax(0, rem-calc(343));
  margin: 1.66rem 0;

  > .link {
    margin: 0;
    min-height: rem-calc(90);
  }
}

buttons:not(.grid, .legal-links) button {
  width: 50%;
}

.requirements-help {
  color: $coral-red;
  font-size: small;

  position: absolute;
  top: 0;
  bottom: -1rem;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;
}

button.hint {
  position: absolute;
  top: -1.5rem;
  right: 0.5rem;
  padding: 0;
  width: 1.75rem;
  height: 1.75rem;

  border-radius: 50%;
  background: $anthrazit;
}
