body.loading header {
  visibility: hidden;
}

body.loading main {
  visibility: hidden;
}

loadingscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  margin: 0 auto;

  // max-width: $content-max-width + 2rem;

  display: block;
  background: $white;
  // flex-direction: column;
  // align-items: center;

  height: 100vh;
  width: 100vw;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
    background-image: url("static/assets/images/keyvisual.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include breakpoint(md) {
      filter: blur(4px);
      -webkit-filter: blur(4px);
    }
  }

  z-index: map-get($z-indexes, loading);

  // transform: translateY(0rem);
  // transition: transform 0.8s ease;
  animation: fade-in 0.8s both;
  &.collapse {
    // transform: translateY(-100vh);
    animation: fade-out 0.8s 2s both;
  }
  .wordmarks {
    position: relative;
    background-image: url("static/assets/wordmarks.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: calc(100% - 4rem);
  }
}
