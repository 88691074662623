// colors
$white: #fff;
$light-gray: #d2d2d2;
$gray: #888;
$dark-gray: #333;
$black: #000;

$cobalt-blue--light: lighten(#46b2ff, 20%);
$cobalt-blue: #0353dc;
$cobalt-blue--dark: #002e95;
$cobalt-blue--darker: #0353dc;
$zh-blue: #0f05a0;
$sun-yellow: #ffdf61;
$sun-yellow--light: #ffeeb6;
$coral-red: #e22d53;
$coral-red--light: #fbdbd4;
$anthrazit: #2a2a2a;

$breakpoints: (
  sm: 0rem,
  md: 48rem,
  lg: 64rem,
);

$grid-fractions: (1, 2, 3);

$grid-gutter: (
  sm: 1rem,
  md: 1.3rem,
  lg: 1.3rem,
);

$content-max-width: 32rem;
$block-margin: 1.3rem;

$z-indexes: (
  message: 5,
  header: 10,
  nav: 15,
  modal: 20,
  loading: 30,
);
