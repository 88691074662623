modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background: $coral-red;
  color: $white;
  padding: 1rem 1rem 10vh 1rem;

  z-index: map-get($z-indexes, modal);

  > textcontent {
    display: block;
    @extend .container;
  }

  max-height: 0px;
  animation: pop-up 0.3s both ease-in;

  // &.hint {
  //   height: calc(90vh - 1rem); // account for padding
  // }
  &.hint,
  &.blue {
    border-top: 1px solid $black;
    background: $cobalt-blue;
  }

  button.modal-close,
  button.modal-close:hover,
  button.modal-close:focus {
    // reset
    margin: 0.3rem 0.3rem 2rem;
    margin-left: auto;
    box-shadow: none;
    background: none;

    background-repeat: no-repeat;
    background-position: center;
    background-image: url("static/assets/close-white.svg");
  }

  .legal-links {
    flex-direction: column;
    align-items: center;
    margin-top: 1.6rem;

    > button {
      margin: 0.5rem 0;
      padding: 0.5rem 2rem;

      border: 1px solid $cobalt-blue--dark;
      color: $cobalt-blue--dark;
      background: $white;

      width: 100%;
    }
  }
}
